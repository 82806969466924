<template>
  <div class="form-group">
    <div class="form-row align-items-center my-2">
      <div class="col-auto my-1">
        <label class="dropdown-label">Date: </label>
      </div>
      <div class="col-sm-12 col-md-6 my-1">
        <start-picker @start-picked="onStartPicked" :datetime="starts_value" status-field="#status" start-field="#starts" :min-date="minDate" :location-id="locationId" field-name="schedule[starts]"></start-picker>
      </div>
    </div>
  </div>
</template>
<script>
import StartPicker from '../start_picker/for_schedule_starts.vue'
export default {
  props: {
    locationId: {
      type: Number,
      required: true
    },
    minDate: {
      type: String
    },
    starts: {
      type: String,
      required: true
    }
  },
  components: { StartPicker },
  data() {
    return {
      starts_value: this.starts
    }
  },
  methods: {
    onStartPicked(time) {
      this.starts_value = time
      this.$emit('update:starts', time)
    }
  }
}
</script>
