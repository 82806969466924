<template>
  <div>{{summary}} <a href="#" v-if="showOptions && enabled" @click.prevent="onClick">Edit Recurrence</a></div>
</template>
<script>
import { DaysFromSunday, OrdinalsAbbrev, pickedDayInfoFromStart } from '../utils'
export default {
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    showOptions: {
      type: Boolean,
      required: true
    },
    start: {
      type: String,
      required: true
    },
    frequency: {
      type: String,
      required: true,
      validator(value) {
        return ['weekly', 'monthly'].indexOf(value) !== -1
      }
    },
    interval: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0
      }
    },
    repeatBy: {
      type: String,
      default: '',
      validator(value) {
        return ['day_of_week', 'day_of_month'].indexOf(value) !== -1
      }
    },
    repeatOn: {
      type: Number | Object,
      required: true
    }
  },
  computed: {
    repeatOnAsDays() {
      var days = []
      if (typeof(this.repeatOn) == 'number') {
        days.push(DaysFromSunday[this.repeatOn] + 's')
        return days
      }
      for(var d in this.repeatOn) {
        days.push(DaysFromSunday[this.repeatOn[d]] + 's')
      }
      return days
    },
    summary() {
      var summary
      if (this.frequency == null) {
        return
      }
      // capitalize
      summary = [this.frequency.charAt(0).toUpperCase() + this.frequency.slice(1)];

      if (this.interval > 1) {
        summary = ["Every " + this.interval];
        if (this.frequency == 'monthly') {
          summary.push('months');
        } else {
          summary.push('weeks');
        }
      }
      summary.push('on')
      if (this.frequency == 'monthly') {
        if (this.repeatBy == 'day_of_month') {
          summary.push('day ' + this.repeatOn);
        }
        if (this.repeatBy == 'day_of_week') {
          if (typeof(this.repeatOn) == 'number') {
            summary.push(this.repeatOnAsDays.join(', '))
          } else {
            for(var r in this.repeatOn) {
              const ro = this.repeatOn[r]
              const day = DaysFromSunday[r]
              summary.push('the ' + OrdinalsAbbrev[ro] + ' ' + day)
            }
          }
        }
      }
      if (this.frequency == 'weekly') {
        summary.push(this.repeatOnAsDays.join(', '))
      }
      return summary.join(' ')
    }
  },
  methods: {
    onClick() {
      if (this.enabled) {
        this.$emit('show-options')
      }
    }
  }
}
</script>
