<script>
import DatePickerWithInput from './date_picker_with_input.vue'

export default {
  extends: DatePickerWithInput,
  props: {
    formatString: {
      type: String,
      default: 'MMMM dS, yyyy h:mm tt'
    }
  },
  computed: {
    isTimePicker() {
      return true
    }
  },
  methods: {
  }
}
</script>
