<template>
  <start-picker @start-picked="onStartPicked" :datetime="datetime" :min-date="minDate" field-name="aasd" :show="show" :location-id="locationId"></start-picker>
</template>
<script>
import StartPicker from './start_picker_with_input.vue'
import { timeToAppFormat } from '../utils'

export default {
  props: {
    minDate: {
      type: String
    },
    locationId: {
      type: Number,
      required: true
    },
    datetime: {
      type: String
    }
  },
  data() {
    return {
      show: true,
      quoteOnly: false
    }
  },
  components: { StartPicker },
  computed: {},
  methods: {
    onStartPicked(time) {
      this.show = false
      this.$emit('start-picked', timeToAppFormat(time))
    },
   },
   watch: {}
}
</script>
