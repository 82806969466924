<template>
  <div class="form-group">
    <div class="form-row align-items-center my-2">
      <div class="col-12 my-1">
        <label class="form-item dropdown-label">Repeat on:</label>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-6 my-1" v-for="day in Days">
          <label class="checkbox-set">{{day.day}}
            <input type="radio" :value="day.idx" :key="day.idx" name="schedule[repeat_on][]" v-model="repeat_on_value" @change="onRepeatOnValueChange" />
            <span class="checkmark checkmark-blue"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DaysFromSunday,
} from '../utils'

export default {
  props: {
    pickedDayInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    Days() {
      const days = DaysFromSunday
      return days.map(function(currentValue, index, array) {
        return {
          idx: index,
          day: currentValue
        }
      })
    }
  },
  data() {
    return {
      repeat_on_value: this.pickedDayInfo.dayInWeek
    }
  },
  methods: {
    onRepeatOnValueChange() {
      this.$emit('update:repeat_on_weekday', this.repeat_on_value)
    }
  }
}
</script>
