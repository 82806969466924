import {
  cal,
  dateToAppFormat,
  timeToAppFormat,
  pickedDayInfoFromStart
} from '@/utils'
//// Passing Date objects do not trigger computed values to change
import Vue from 'vue'
import VueResource from 'vue-resource/dist/vue-resource'
Vue.use(VueResource);
import ScheduleSummary from '@/schedule/summary.vue'
import DateField from './date_field.vue'
import RecurrenceOptions from './recurrence_options.vue'
import WeeklyRepeatOn from './weekly_repeat_on.vue'
import RepeatOnDayPicker from './repeat_on_day_picker.vue'
import unassignment_confirmation_mixin from '@/shared/confirmation_for_unassignment_mixin'
import ReasonField from '@/shared/inputs/text.vue'

export default {
  components: {
    DateField,
    RepeatOnDayPicker,
    RecurrenceOptions,
    WeeklyRepeatOn,
    ScheduleSummary,
    ReasonField
  },
  mixins: [unassignment_confirmation_mixin],
  props: {
    event: {
      type: Object
    },
    mode: {
      type: String,
      default: 'single'
    },
    theme: {
      type: String
    }
  },
  computed: {
    reservationChanged() {
      var changes = this.values;
      return this.start != changes.start ||
        this.schedule_interval != changes.interval;
      this.schedule_repeat_on != changes.repeat_on;
      this.schedule_repeat_by != changes.repeat_by;
      this.schedule_frequency != changes.frequency;
    },
    reservationId() {
      return this.event.id
    },
    hasJob() {
      return this.event.has_job
    },
    hasInProgressJob() {
      return this.event.has_in_progress_job
    },
    cantReschedule() {
      if (this.isClient) {
        return false
      }
      return this.hasInProgressJob
    },
    requireConfirmUnassign() {
      if (this.isClient) {
        return false
      }
      return this.hasJob
    },
    when() {
      return this.event.when
    },
    intervalAsWeeks() {
      let w = 1
      if (this.schedule_frequency == 'monthly') {
        w = 4
      }
      return this.schedule_interval * w
    },
    recurring() {
      return this.plan != null
    },
    schedule_repeat_on: {
      get() {
        if (this.pickedDayInfo == null) {
          console.error('pickedDayInfo is NULL')
          return null
        }
        if (this.schedule_frequency == 'weekly') {
          return this.pickedDayInfo.dayInWeek
        }
        if (this.schedule_repeat_by == 'day_of_month') {
          return this.pickedDayInfo.monthlyIdx
        }
        if (this.schedule_repeat_by == 'day_of_week') {
          var r = {}
          r[this.pickedDayInfo.dayInWeek] = [ this.pickedDayInfo.weekIdx ]
          return r
        }
        if (event.schedule) {
          console.error('Invalid repeat_by', this.schedule_repeat_by)
        }
      },
      set(v) {
        const a = pickedDayInfoFromStart(this.start, this.schedule_frequency, this.schedule_repeat_by, v)
        console.log("set pickedDayInfo in schedule common schedule_repeat_on", a, 'from',this.start, this.schedule_frequency, this.schedule_repeat_by, v)
        this.setPickedDayInfo(a)
      }
    },
    singleEventMode() {
      return this.mode == 'single'
    },
    showDateField() {
      return this.start != null
    },
    showRepeatDayPicker() {
      return this.showRecurrenceOptions && !this.singleEventMode && (this.schedule_frequency == 'monthly')
    },
    showWeeklyRepeatOnOptions() {
      return (this.schedule_repeat_by != null) && !this.singleEventMode && (this.schedule_frequency == 'weekly')
    },
    showWarningAboutNotification() {
      return this.isAdmin || this.isAgent
    },
    summaryParams() {
      return {
        start: this.start,
        interval: this.schedule_interval,
        frequency: this.schedule_frequency,
        repeatOn: this.schedule_repeat_on,
        repeatBy: this.schedule_repeat_by
      }
    },
    values() {
      var result = {
        frequency: this.schedule_frequency,
        status: this.status,
        interval: this.schedule_interval,
        repeat_on: JSON.stringify(this.schedule_repeat_on),
        repeat_by: this.schedule_repeat_by,
        when: this.when,
        notify_cleaners: this.notifyCleaners,
        update_what: 'from_this',
        reason: this.reason
      }

      if (this.isClient) {
        this.reason = 'Client changed the schedule'
      }

      if (this.start != '') {
        result.starts = this.start
      }
      if (this.singleEventMode) {
        result.update_what = 'only_this'
      }

      return result;
    },
  },
  data() {
    return {
      dateFormatString: 'MMMM dS, yyyy',
      forced: false,
      minDate: null,
      show: false,
      showStartsPicker: false,
      showRecurrenceOptions: false,
      startsFormatString: 'MMMM dS, yyyy h:mm tt',
      startPicked: null,
      //// the actual date of the reservation that is being updated
      start: null,
      schedule_frequency: null,
      schedule_interval: null,
      //// the real start of the schedule
      schedule_begins: null,
      pickedDayInfo: {},
      schedule_repeat_by: null,
      hasSkips: false,
      skipsConfirmed: false,
      notifyCleaners: false,
      reason: null
    }
  },
  methods: {
    close() {
      this.show = false
    },
    //// minDate is set to the start of the day, not to the original start time
    //// so it is possible to pick another time on the same day
    initEvent(event) {
      this.minDate = dateToAppFormat(Date.today())
      this.start = null
      this.schedule_frequency = null
      this.schedule_interval = null
      this.schedule_repeat_by = null
      this.schedule_repeat_on = null
      this.schedule_begins = null
      if (event) {
        var parsed_date = Date.parse(event.start)
        if (parsed_date.toString() != 'Invalid Date') {
          this.start = timeToAppFormat(parsed_date)
        }
        if (event.schedule) {
          this.schedule_frequency = event.schedule.frequency
          this.schedule_interval = event.schedule.interval
          this.schedule_repeat_by = event.schedule.repeat_by
          this.schedule_repeat_on = event.schedule.repeat_on
          this.schedule_begins = timeToAppFormat(parsed_date)
          this.updatePickedDayInfoFromStart()
        }
      }
      this.startPicked = this.start
    },
    onRepeatOnDayPicked(params) {
      this.setPickedDayInfo(params)
    },
    onUpdateRepeatOnWeekday(day) {
      this.schedule_repeat_on = day
    },
    onStartsChange(new_value) {
      this.start = new_value
      this.startPicked = timeToAppFormat(this.start)
    },
    updatePickedDayInfoFromStart() {
      this.setPickedDayInfo(pickedDayInfoFromStart(this.start, this.schedule_frequency, this.schedule_repeat_by, this.schedule_repeat_on))
    },
    setPickedDayInfo(newPickedDayInfo) {
      for(var p in newPickedDayInfo) {
        Vue.set(this.pickedDayInfo, p, newPickedDayInfo[p])
      }
    },
    updateReservation(do_not_notify) {
      this.notifyCleaners = !do_not_notify
      this.hasSkips = false
      if (this.values.frequency == 'monthly') {
        if (this.values.repeat_by == 'day_of_month') {
          if (this.values.repeat_on > 28) {
            this.hasSkips = true
          }
        }
        if (this.values.repeat_by == 'day_of_week') {

        }
      }
      if (this.hasSkips) {
        if (this.skipsConfirmed) {
          this.$emit('update:loading', true)
          this.$http.patch('/calendar/reservations/' + this.reservationId, this.values).then(response => {
            this.$emit('reservation-updated')
          }, response => {
            this.$emit('update:loading', false)
            this.$flasher.error(response.body.error_hash.error)
          })
        }
      } else {
        this.$emit('update:loading', true)
        this.$http.patch('/calendar/reservations/' + this.reservationId, this.values).then(response => {
          this.$emit('reservation-updated')
        }, response => {
          this.$flasher.errors(response.data.errors)
          this.$emit('update:loading', false)
        })
      }
    },
    updateStartBasedOnRepeatOn() {
      var newDate = new Date(this.startPicked).last().sunday().addDays(this.schedule_repeat_on)
      this.start = timeToAppFormat(newDate)
      this.startPicked = this.start
    },
    save() {
      if (!this.reservationChanged) {
        return
      }
      if (this.cantReschedule) {
        this.$dialog.alert('It is not possible to reschedule a visit that has a job in progress')
        return
      }

      if (this.requireConfirmUnassign) {
        this.confirmWithNotificaton(this.updateReservation)
      } else {
        this.updateReservation()
      }
    }
  },
  watch: {
    event: {
      handler(new_value) {
        //console.log("schedule mixin event changed", new_value)
        this.initEvent(new_value)
      },
      deep: true
    },
    schedule_frequency(new_value) {
      //console.log("schedule mixin schedule_frequency changed", new_value)
    },
    startPicked(new_value) {
      //console.log("schedule mixin startPicked changed", new_value)
      this.updatePickedDayInfoFromStart()
    }
  }
}
