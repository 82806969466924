<template>
  <div>
    <div class="form-group">
      <div class="form-row align-items-center my-2">
        <div class="col-12 my-1">
          <label class="form-item dropdown-label">Repeat by:</label>
        </div>
        <div class="row w-100">
          <div class="col-md-6 col-sm-6 col-6 my-1">
            <label class="checkbox-set">day of the month
              <input type="radio" value="day_of_month" name="schedule[repeat_by]" v-model="repeat_by_value" @change="onRepeatByChange" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
          <div class="col-md-6 col-sm-6 col-6 my-1">
            <label class="checkbox-set">day of the week
              <input type="radio" value="day_of_week" name="schedule[repeat_by]" v-model="repeat_by_value" @change="onRepeatByChange" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="col-12" v-for="(week, weekIdx) in weeks">
        <div class="text-center">
          <div v-if="repeat_by_value == 'day_of_week'" class="row">
            <div class="mx-auto">
              <div class="col-1 my-auto">
                <p class="my-auto">{{ordinalIndex(weekIdx)}}</p>
              </div>
              <div class="col-auto">
                <div class="btn-group my-1">
                  <button v-for="(dayInfo, weeklyDayIdx in daysInWeek(week)" type="button" v-bind="dayState(weeklyDayIdx, dayInfo, weekIdx)" @click="dayClick(weeklyDayIdx, dayInfo, weekIdx)">{{DaysAbbrev[weeklyDayIdx]}}</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="btn-group btn-matrix">
            <button v-for="(dayInfo, weeklyDayIdx) in daysInWeek(week)" type="button" v-bind="dayState(weeklyDayIdx, dayInfo, weekIdx)" @click="dayClick(weeklyDayIdx, dayInfo, weekIdx)">{{dayInfo.monthlyIdx > 0 ? dayInfo.monthlyIdx : ''}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { cal, DaysAbbrev, daysInWeek } from '@/utils'
export default {
  props: {
    pickedDayInfo: {
      type: Object,
      required: true
    },
    datetime: {
      type: String,
      required: true
    },
    repeatBy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      repeat_by_value: this.repeatBy,
      weeks: [],
    }
  },
  mounted() {
    var m = cal.monthDays(this.dateValue.getFullYear(), this.dateValue.getMonth())
    for (var i=0; i<m.length; i++) this.weeks.push(m[i])
  },
  computed: {
    dateValue() {
      return Date.parse(this.datetime)
    },
    DaysAbbrev() {
      return DaysAbbrev
    }
  },
  methods: {
    dayState(weeklyDayIdx, dayInfo, weekIdx) {
      var cls
      var disabled
      if (dayInfo.monthlyIdx > 0) {
        cls = 'btn-blue-line'
      } else {
        disabled = true
        cls = 'btn-light'
      }
      if (this.isRepeatsOn(weeklyDayIdx, dayInfo, weekIdx)) {
        cls = 'btn-blue'
      }
      if (this.repeat_by_value == 'day_of_month') {
        cls += ' cal-btn'
      }
      return { class: 'btn btn-sm ' + cls, disabled: disabled }
    },
    dayClick(weeklyDayIdx, dayInfo, weekIdx) {
      var dayInWeek = dayInfo.weeklyIdx + 1
      if (dayInWeek == 7) {
        dayInWeek = 0
      }
      const info = { dayInWeek: dayInWeek, weekIdx: weekIdx + 1, monthlyIdx: dayInfo.monthlyIdx }
      this.$emit('repeat-on-day-picked', info)
    },
    daysInWeek(week) {
      return daysInWeek(week)
    },
    isRepeatsOn(weeklyDayIdx, dayInfo, weekIdx) {
      if (this.pickedDayInfo) {
        if (this.pickedDayInfo.monthlyIdx == dayInfo.monthlyIdx)  {
          return true
        }
      }
      return false
    },
    onRepeatByChange() {
      this.$emit('update:repeat-by', this.repeat_by_value)
    },
    ordinalIndex(index) {
      return Date.today().set({day: 1 + index }).toString('dS')
    }
  },
}
</script>
