<template>
  <time-picker-with-input @start-picked="onStartPicked" :datetime="datetime" :availabilities="availabilities" @reload-availabilities="reloadAvailabilities" :loading="loading" :show="show" :min-date="minDate">
    <template slot="spinner"><spinner :show="loading" :use-modal="false"></spinner></template>
  </time-picker-with-input>
</template>
<script>
import TimePickerWithInput from './time_picker_with_input.vue'
import AvailabilitiesMixin from './availabilities_mixin.js'
import StartPickerMixin from './start_picker_mixin.js'

export default {
  components: { TimePickerWithInput },
  mixins: [AvailabilitiesMixin, StartPickerMixin],
  methods: {  }
}
</script>
