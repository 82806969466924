<template>
  <div class="form-group">
    <div class="form-row align-items-center my-2">
      <div class="col-auto my-3">
        <label class="form-item dropdown-label mt-1" for="">Repeats:</label>
      </div>
      <div class="col-auto">
        <select class="white-custom-select w-auto mr-sm-8" name="schedule[frequency]" v-model="frequency_value" @change="$emit('update:frequency', frequency_value)">
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <div class="col-auto my-3">
        <label class="form-item dropdown-label mt-1">Repeat Every:</label>
      </div>
      <div class="col-auto">
        <select class="white-custom-select w-auto mr-sm-8" name="schedule[interval]" v-model.number="interval_value" @change="$emit('update:interval', interval_value)">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    frequency: {
      type: String,
      required: true
    },
    interval: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      frequency_value: this.frequency,
      interval_value: this.interval
    }
  }

}
</script>
