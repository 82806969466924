<template>
  <div>
    <input :disabled="!enabled" type="text" class="form-control" :name="fieldName" :value="formattedDate" @click="showPicker = true" readonly="readonly" />
    <div class="start-picker start-picker-dropdown-bottom-right dropdown-menu" :class="{show: showPicker}">
       <component v-bind:is="mode" :datetime="tmpDateValue.toString()" :availabilities="availabilities" @change-mode="onChangeMode" @change-year="onChangeYear" @change-month="onChangeMonth" @change-day="onChangeDay" @year-picked="onYearPicked" @month-picked="onMonthPicked" @day-picked="onDayPicked" @hour-picked="onHourPicked" @start-picked="$emit('start-picked', $event)" :loading="loading"></component>
       <slot name="spinner"></slot>
    </div>
  </div>
</template>
<script>
import DatePicker from './date_picker.vue'

export default {
  extends: DatePicker,
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    fieldName: {
      type: String,
      require: true
    },
    show: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return { showPicker: false }
  },
  mounted() {
    this.mode = 'days'
    this.pickedDate = this.datetime
  }
}
</script>
